import React from 'react';
import '../Styles/Common.css';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import Spinner from '../component/Spinners';

const ContactUs = () => {
	const { HomePageList } = useSelector((state) => state.festivWebsite);

	const applyStyles = {
		fontFamily: HomePageList?.fontFamily,
		color: HomePageList?.fontColor,
	};

	const mapStyles = {
		height: '381px',
		width: '100%',
	};
	const center = {
		lat: parseFloat(HomePageList?.latitude),
		lng: parseFloat(HomePageList?.longitude),
	};
	return (
		(HomePageList === '' && <Spinner />) || (
			<div className='bannerBackground'>
				<div className='container-fluid BackGroundBlur' style={applyStyles}>
					<div className='container contactMain  px-3 py-3'>
						<div className='row ticketDetailbanner'>
							<h2> Contact Us</h2>
						</div>
						<hr />
						<div className='row'>
							<div className='col-lg-5'>
								<div className='footer-infoItem'>
									<ul>
										<li className='tel'>
											<a href={`tel:${HomePageList?.contactPhoneNo}`}>
												{HomePageList?.contactPhoneNo}
											</a>
										</li>
										<li className='address'>{HomePageList?.locationName}</li>
										<li className='mail'>
											<a
												href={`mailto:${HomePageList?.contactAdminEnquiryEmail}`}>
												{HomePageList?.contactAdminEnquiryEmail}
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12'>
								<GoogleMap
									center={center}
									zoom={4}
									mapContainerStyle={mapStyles}
									draggable={true}>
									<Marker
										position={{
											lat: parseFloat(HomePageList?.latitude),
											lng: parseFloat(HomePageList?.longitude),
										}}>
										<InfoWindow
											position={{
												lat: parseFloat(HomePageList?.latitude),
												lng: parseFloat(HomePageList?.longitude),
											}}>
											<>
												<div className='fs-6  fw-normal text-dark'>
													{' '}
													{HomePageList?.locationName}
												</div>
											</>
										</InfoWindow>
									</Marker>
								</GoogleMap>
								{/* <MapSection
        item={HomePageList}
        /> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default ContactUs;
