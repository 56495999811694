import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorMessage, loadingStatus, successMessage, Subscription } from '../Redux/Slice';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { errTitle, scc, poscent, posTop, errIcon, sccIcon, BtnCanCel, BtnGreat } from '../Constant';
import { useFormik } from 'formik';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import nbysflogo from '../assets/logo.png';
import facebook from '../assets/facebook.png';
import Instagram from '../assets/instagram.png';

const Footer = ({ items }) => {
	const { success, error } = useSelector((state) => state.festivWebsite);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const mapStyles = {
		height: '381px',
		width: '100%',
	};

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validate: (values) => {
			const errors = {};

			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				errors.email = 'Must be valid e-mail';
			}

			if (Object.keys(errors).length === 0) {
				formik.setStatus({ isSubmitting: true });
			}

			return errors;
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			dispatch(Subscription(values));

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
			resetForm();
		},
	});

	const Notification = useCallback(
		(val, tit, pos, ico, btn) => {
			Swal.fire({
				position: `${pos}`,
				title: `${tit}`,
				text: `${val}`,
				icon: `${ico}`,
				confirmButtonText: `${btn}`,
			}).then(function () {
				navigate('/');
				dispatch(errorMessage({ errors: '' }));
				dispatch(successMessage({ successess: '' }));
				dispatch(loadingStatus({ loadingStatus: false }));
			});
		},
		[navigate, dispatch],
	);

	useEffect(() => {
		if (success === 'Thank you for connecting with us') {
			Notification(success, scc, posTop, sccIcon, BtnGreat);
		}
		if (error !== 'Please try again') {
			error && Notification(error, errTitle, poscent, errIcon, BtnCanCel);
		}
	}, [error, success, Notification]);

	const center = { lat: parseFloat(items?.latitude), lng: parseFloat(items?.longitude) };
	const d = new Date();
	let year = d.getFullYear();
	return (
		<>
			<footer className='footer'>
				<div className='container footerContainer'>
					<div className='row'>
						<div className='col-lg-12 col-sm-12'>
							<div className='row d-flex flex'>
								<div className='col-lg-3 col-sm-12'>
									<div className='footer-info'>
										<h1>CONTACT US</h1>
										<ul>
											<li className='tel'>
												<a href='tel:732 799 4067'>
													{items?.contactPhoneNo}
												</a>
											</li>
											<li className='address'>{items?.locationName}</li>
											<li className='mail'>
												<a
													href={`mailto:${items?.contactAdminEnquiryEmail}`}>
													{items?.contactAdminEnquiryEmail}
												</a>
											</li>
											<li>
												<div>
													<a
														href='https://www.facebook.com/NBYSFofficial?mibextid=LQQJ4d'
														target='_blank'
														rel='noopener noreferrer'>
														<img
															src={facebook}
															alt='facebook'
															width={'50'}
															height={'50'}
														/>
													</a>
												</div>
												<div>
													<a
														href='https://www.instagram.com/nbysfofficial/'
														target='_blank'
														rel='noopener noreferrer'>
														<img
															src={Instagram}
															alt='instagram'
															width={'50'}
															height={'50'}
														/>
													</a>
												</div>
											</li>
										</ul>
									</div>
									<div>
										<img
											src={nbysflogo}
											className='Header-logo'
											alt='Festiv Logo'
										/>
									</div>
								</div>
								<div className='col-lg-3 col-sm-12'>
									<div className='footer-cardinfo'>
										<h1>Festiv Spark</h1>
										<p>
											Enjoy fun for the whole family at the North Brunswick
											Youth Sports Festival. Discover carnival rides,
											entertainment, food and much more!{' '}
										</p>
									</div>
								</div>
								<div className='col-lg-2 col-sm-12'>
									<div className='footer-cardinfo'>
										<h1>SITE MAP</h1>
										<p>
											<Link to='/'>Home</Link>
										</p>
										<p>
											<Link to='/about'>About</Link>
										</p>
										<p>
											<Link to='/event'>Event</Link>
										</p>
										<p>
											<Link to='/sponsor'>Sponsor</Link>
										</p>
										<p>
											<Link to='/vendor'>Vendor</Link>
										</p>
										<p>
											<Link to='/festivalhours'>Festival Hours</Link>
										</p>
										<p>
											<Link to='/contact'>Contact</Link>
										</p>
										<p>
											<Link to='/privacypolicy'>
												Tickets Policy, Privacy, Cookies , Other Terms &
												Conditions
											</Link>
										</p>
									</div>
								</div>
								<div className='col-lg-4' id='map'>
									<section>
										<GoogleMap
											center={center}
											zoom={4}
											mapContainerStyle={mapStyles}
											draggable={true}>
											<Marker
												position={{
													lat: parseFloat(items?.latitude),
													lng: parseFloat(items?.longitude),
												}}>
												<InfoWindow
													position={{
														lat: parseFloat(items?.latitude),
														lng: parseFloat(items?.longitude),
													}}>
													<>
														<div className='fs-6  fw-normal text-dark'>
															{' '}
															{items?.locationName}
														</div>
													</>
												</InfoWindow>
											</Marker>
										</GoogleMap>
									</section>
								</div>
							</div>
							<div className='row d-flex justify-content-center'>
								<div className='col-lg-7 col-sm-12 col-md-12"'>
									<div className='email-signup-main'>
										<div className='emailSignup modalOnly'>
											<div>
												<h5>STAY CONNECTED</h5>
												<p>
													Don’t be left behind! Sign up today for the
													latest entertainment updates!
												</p>
											</div>
											<form onSubmit={formik.handleSubmit}>
												<div className='emailInput'>
													<div
														className='emailInputField'
														title='Privacy Guaranteed'>
														<input
															placeholder='Enter Your Email*'
															type='text'
															id='email'
															onChange={formik.handleChange}
															value={formik.values.email}
															onBlur={formik.handleBlur}
															className='inputFieldTextbox textbox'
														/>
													</div>
													<div className='emailInputSubmit'>
														<button
															type='submit'
															className='GetUpdates'>
															Get Updates
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div className='footer-bottom'>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<a
								href='https://sigursolutions.com/'
								target='_blank'
								rel='noopener noreferrer'
								style={{ textDecoration: 'none' }}>
								<p>Copyright ©{year}, NBYSF - Sigur Solutions LLC. All Rights Reserved.</p>
							</a>
						</div>
						<div className='col text-end'>
							<a
								href='https://festivtickets.com/'
								target='_blank'
								rel='noopener noreferrer'
								style={{ textDecoration: 'none' }}>
								<p>Powered by Festiv Tickets</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
Footer.propTypes = {
	items: PropTypes.object.isRequired,
};
