import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../Styles/Events.css';
import { Link } from 'react-router-dom';
import GoogleTranslate from '../component/GoogleTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { EventPageData } from '../Redux/Slice';
import LocationImg from '../assets/EventDetail/location.svg';
import InfoImg from '../assets/EventDetail/info.svg';
import WeatherImg from '../assets/EventDetail/cloudy-day.svg';
import NoEventList from '../assets/NoEvent.jpg';
import { convertDateTime } from '../TimeConvert';
import Spinner from 'react-bootstrap/Spinner';

export const BasicSpinner = () => {
	return <Spinner animation='border' variant='primary' />;
};

const EventList = () => {
	const dispatch = useDispatch();

	const { EventPageList, HomePageList, Loading } = useSelector((state) => state.festivWebsite);
	const [eventWeatherData, setEventWeatherData] = useState([]);
	const [weatherData, setWeatherData] = useState([]);

	useEffect(() => {
		dispatch(EventPageData());
	}, [dispatch]);

	const applyStyles = {
		fontFamily: EventPageList?.fontFamily,
		color: EventPageList?.fontColor,
	};

	const Locationmap = EventPageList?.eventList?.map(({ eventLocation }) => ({
		lat: parseFloat(eventLocation?.latitude),
		lng: parseFloat(eventLocation?.longitude),
	}));
	function celsiusToFahrenheit(celsius) {
		const val = (celsius * 9) / 5 + 32;
		return val?.toFixed(0);
	}
	useEffect(() => {
		const fetchWeatherData = async () => {
			const apiKey = 'dd5a361fdf05b43f641850428252624d';
			const units = 'metric';

			const promises = Locationmap?.map(async (location) => {
				const { lat, lng } = location;
				const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=${units}&appid=${apiKey}`;
				const response = await fetch(url);
				const data = await response.json();
				return data;
			});
			var results;
			if (promises) {
				results = await Promise.all(promises);
			}
			setWeatherData(results);
		};

		fetchWeatherData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [EventPageList]);

	useEffect(() => {
		if (Loading) {
			setEventWeatherData(<BasicSpinner />);
		} else if (EventPageList && weatherData) {
			const minArrayLength = Math.min(EventPageList?.eventList?.length, weatherData?.length);
			const eventWeatherDatas = [];

			for (let index = 0; index < minArrayLength; index++) {
				const val = EventPageList?.eventList[index];
				const currentWeather = weatherData[index];
				const currentWeatherDes = weatherData[index]?.weather[0];

				const temperature = currentWeather?.main?.temp
					? `${celsiusToFahrenheit(currentWeather.main.temp)}°F`
					: 'No current data';
				const description = currentWeatherDes?.description
					? currentWeatherDes.description
					: 'No current data';

				eventWeatherDatas.push(
					<Row key={index} className='eventListCard'>
						<Col lg={3} md={4} className='EventImageCard'>
							<img
								src={
									val?.event?.eventImage
										? val?.event?.eventImage
										: HomePageList?.navbarImage
								}
								alt=''
							/>
						</Col>
						<Col
							lg={9}
							md={8}
							className='mt-3 d-block eventContent justify-content-start align-items-start flex-column'>
							<h5>
								{val?.event?.eventName.charAt(0).toUpperCase() +
									val?.event?.eventName.slice(1)}
							</h5>
							<Row className=' mt-4 mb-4 eventDetails d-flex justify-content-center align-items-start'>
								<Col lg={3}>
									<div>
										<div>
											<span className='d-flex'>
												<img src={LocationImg} alt='' />
												<h6 className='px-2'>Address</h6>
											</span>
										</div>
										<div>
											<span className='py-1'>
												{val?.eventLocation?.address}
											</span>
										</div>
									</div>
								</Col>
								<Col lg={5}>
									<div>
										<div className='d-flex'>
											<img src={InfoImg} alt='' />
											<h6 className='px-2'>Event Schedule</h6>
										</div>
										<div className='d-flex flex-column'>
											{val?.event?.eventSchedule.map((item, key) => (
												<div
													key={key}
													className='eventListDate'
													dangerouslySetInnerHTML={{
														__html: convertDateTime(
															item?.eventDateAndTimeFrom,
															item?.eventDateAndTimeTo,
														),
													}}></div>
											))}
										</div>
									</div>
								</Col>
								<Col lg={4}>
									<div>
										<div className='d-flex'>
											<img src={WeatherImg} alt='' />
											<h6 className='px-2'>Weather</h6>
										</div>
										<div className='d-flex flex-column'>
											<span className='py-1'>
												Temperature:{' '}
												<span className='text-dark fw-bold'>
													{/* {' '}
													{celsiusToFahrenheit(
														currentWeather?.main?.temp,
													)}
													°F */}
													{temperature}
												</span>
											</span>
											<span className='py-1'>
												Description:{' '}
												<span className='text-dark fw-bold'>
													{/* {currentWeatherDes?.description} */}
													{description}
												</span>
											</span>
										</div>
									</div>
								</Col>
							</Row>
							<Col lg={8} className='my-2 w-100'>
								<Link to={`/eventdetails/${val?.event?._id}`}>
									<button className='viewbtn'>View Details</button>
								</Link>
							</Col>
						</Col>
					</Row>,
				);
			}
			setEventWeatherData(eventWeatherDatas);
		} else if (!Loading) {
			setEventWeatherData(<img src={NoEventList} className='Noevent' alt='logo'></img>);
		} else if (EventPageList === undefined) {
			setEventWeatherData(<img src={NoEventList} className='Noevent' alt='logo'></img>);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [EventPageList, weatherData]);

	return (
		<div className='eventMain'>
			<Container fluid className='eventBlur' style={applyStyles}>
				<Container className='d-flex eventContainer flex-column'>
					<Row className='d-flex justify-content-center align-items-center '>
						<Col
							lg={4}
							className='eventHeading d-flex justify-content-center align-items-center'>
							<h1 className='fw-bold'>Events</h1>
						</Col>
						<Col lg={4} className='d-flex justify-content-center align-items-center '>
							<a href='/ticketdetail'>
								<button className='btn-txt1 ticketButtonMain'>
									{' '}
									Click here to GET TICKETS{' '}
								</button>
							</a>
						</Col>
						<Col
							lg={4}
							className='d-flex justify-content-center align-items-center eventTranslate'>
							<GoogleTranslate />
						</Col>
					</Row>
					<Row className='d-flex justify-content-center align-items-center '>
						<Col lg={10}>
							<hr />
						</Col>
					</Row>
					<Row className='d-flex  align-items-center flex-column h-100'>
						<Col
							lg={10}
							className='d-flex h-100 justify-content-center align-items-center flex-column mb-3 eventListCardScroll'>
							{eventWeatherData}
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
};

export default EventList;
